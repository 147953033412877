import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Checkbox = (props) => {
  const { field, value, filter, handleChange } = props
  const {type, name, filter: fieldFilter, placeholder, required, className, options, isMulti = false } = field
  return (
    <div className={`${className}${value[slugify(name)] ? ' active' : ''}${required ? ' required' : ''}`}>
      <label>{placeholder}</label>
      <div className="checkboxes">
        {options && options.filter(option => !option.filter || option.filter.includes(filter)).map((option, index) => (
          <label htmlFor={slugify(option.label)} className="checkbox" key={index}>
            <input name={isMulti ? slugify(name) : slugify(option.label)} id={slugify(option.label)} checked={isMulti ? value[slugify(name)] && value[slugify(name)].length > 0 && value[slugify(name)].split(', ').includes(option.value) : value[slugify(option.label)] === option.value} type="checkbox" onChange={(e) => handleChange(e, false, isMulti)} value={option.value}  />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  )
}