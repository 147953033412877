import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'
import { StaticQuery, graphql, navigate } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { objectToQuerystring, slugify } from '../../utils/helpers'
const recaptchaRef = React.createRef()
import * as FormFields from './Fields'
import { FaCircleNotch, FaExclamation } from 'react-icons/fa'
import ls from 'local-storage'

class Index extends Component {
  constructor(props) {
    const { thankYou = null, data, id = null } = props
    const { siteSettings, allWordpressWpForm } = data
    var settings =
      id &&
      allWordpressWpForm &&
      allWordpressWpForm.nodes.filter(i => i['wordpress_id'] === parseInt(id))
    settings =
      settings && settings[0] && settings[0].formJson && settings[0].formJson
    const hasRadioEnquiry = settings.formFields.find(
      field => field.name === 'Enquiry' && field.type === 'radio'
    )
    super(props)
    this.state = {
      error: null,
      loading: false,
      submitted: false,
      ready: false,
      id: id,
      thankYou: thankYou,
      siteKey: siteSettings.options.googleSiteKey,
      settings: settings && settings.formFields && settings.formFields,
      filter: hasRadioEnquiry ? hasRadioEnquiry.options[0].value : null, // used for filtering field visibility
      formValidate: false,
      lead: {
        location:
          typeof window !== `undefined` ? window.location.href : 'Server',
        recipientAddress:
          settings && settings.recipientAddress
            ? settings.recipientAddress
            : null,
        formId: id,
        ...(hasRadioEnquiry && { enquiry: hasRadioEnquiry.options[0].value }), // conditional lead property
      },
    }
  }

  handleValidate = e => {
    this.setState({ formValidate: true })
  }

  handleSubmit = e => {
    e.preventDefault()

    // scroll to top of form
    document.querySelector('html, body').scrollTop =
      document.querySelector('.lead-form').offsetTop - 160

    const recaptchaValue =
      recaptchaRef.current && recaptchaRef.current.getValue()
    const formReValue = e.target.elements['g-recaptcha-response'].value
    if (formReValue !== '' && recaptchaValue === formReValue) {
      this.captchaSubmit(e)
    }
  }

  checkCaptcha = () => {
    const recaptchaValue =
      recaptchaRef.current && recaptchaRef.current.getValue()
    if (recaptchaValue) {
      this.setState({ ready: true })
    } else {
      this.setState({ ready: false })
    }
  }

  captchaSubmit = async e => {
    this.setState({ loading: true })
    window.dataLayer = window.dataLayer || []
    //const theFunctionsUrl = `/.netlify/functions/leads`
    const leadFunctionUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/leads/v2/submit`
    const appSecret = process.env.GATSBY_WEB_APP_SECRET
    //Add recipient
    const { lead } = this.state
    const { location } = lead
    const formElementsArray = Array.from(e.target.elements)
    const nonFields = ['location', 'recipientAddress', 'formId']
    // Loop each key in the lead state object and remove any that are no longer
    // elements in the form
    // This was to ensure that if fields are optionally removed for example by a
    // radio button, that the field's value does not get pushed to WordPress and
    // into submissions
    Object.keys(lead)
      .filter(key => !nonFields.includes(key))
      .forEach(key => {
        if (formElementsArray.find(el => el.name === key) === undefined) {
          delete lead[key]
        }
      })
    fetch(leadFunctionUrl, {
      method: 'POST',
      body: JSON.stringify({ secret: appSecret, lead: lead }),
    })
      .then(response => {
        if (response.status === 200) {
          window.dataLayer.push({
            event: 'form_submit_success',
            formLocation: location,
          })
          this.setState({ loading: false, submitted: true, lead: {} })
          this.props.thankYou &&
            this.props.thankYou !== '' &&
            navigate(this.props.thankYou)

          // add user to mailchimp audience (if users opts in via checkbox)
          if (lead.newsletter === 'yes' && lead.email) {
            addToMailchimp(lead.email, {
              FNAME: lead.name,
              LNAME: '',
              MMERGE4: lead.suburb, // suburb
            })
              .then(data => {
                // console.log(data); // mc response
                if (data.result === 'success') {
                  window.dataLayer.push({
                    event: 'form_newsletter_subscription',
                    formLocation: location,
                  })
                }
              })
              .catch(() => {
                // unnecessary because Mailchimp only ever returns a 200 status code
                // (see https://www.gatsbyjs.com/plugins/gatsby-plugin-mailchimp/ for how to handle errors)
              })
          }
        } else {
          this.setState({ loading: false, error: true, lead: {} })
        }
      })
      .catch(error => {
        console.error('submitForm Error', error)
        this.setState({ loading: false, error: true, lead: {} })
      })
  }

  // Form change.
  // isFilter value is used in the Radio component onChange
  // Added isFilter to Radio field in Contact Form Settings in WP (ACF Code field)
  // Also added filter array to each option (in ACF Code field) to filter
  // available options depending on the radio button selected
  handleChange = (e, isFilter = false, isMulti = false) => {
    let lead = this.state.lead
    const { filter } = this.state
    //If file
    if (e.target.type === 'file') {
      let fileName = `lead-file-${e.target.name}`
      let file = e.target.files[0]
      lead[fileName] = { data: '', file: '' }

      if (file) {
        const reader = new FileReader(file)
        reader.readAsDataURL(file)
        reader.onload = () => {
          // set image and base64'd image data in component state
          lead[fileName].data = reader.result
          lead[fileName].file = file.name
        }
      }
    } else if (e.target.type === 'checkbox') {
      if (isMulti) {
        if (lead[e.target.name]) {
          const checkedItems = lead[e.target.name].split(', ')
          if (checkedItems.indexOf(e.target.value) >= 0) {
            // Remove selected checkbox value from checkedItems
            checkedItems.splice(checkedItems.indexOf(e.target.value), 1)
          } else {
            // Push selected checkbox value into checkedItems
            checkedItems.push(e.target.value)
          }
          lead[e.target.name] = checkedItems.join(', ')
        } else {
          // Set field to selected checkbox value
          lead[e.target.name] = e.target.value
        }
      } else {
        // Toggle value on off
        lead[e.target.name] === e.target.value
          ? (lead[e.target.name] = '')
          : (lead[e.target.name] = e.target.value)
      }
    } else {
      // All other fields
      lead[e.target.name] = e.target.value
    }
    // if this field "isFilter", set state filter value to input value
    this.setState({ lead, filter: isFilter ? e.target.value : filter })
  }

  render() {
    const {
      loading,
      submitted,
      error,
      lead,
      filter,
      siteKey,
      id,
      settings,
      formValidate,
    } = this.state
    console.log('formValidate:', formValidate)
    if (!id) return 'No form id!'

    if (!settings) return 'There is a problem with the JSON!'

    if (submitted)
      return (
        <div className="lead-form thank-you">
          <h3>Thank you!</h3>
          <p>Your message has been sent.</p>
        </div>
      )

    const formatComponentName = string => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    return (
      <div className="lead-form">
        {error && (
          <div className="error">
            <div>
              There was a error <FaExclamation />
            </div>
          </div>
        )}
        {loading && (
          <div className="loading">
            <div>
              <FaCircleNotch className="spin" /> Loading...
            </div>
          </div>
        )}
        <form
          onSubmit={event => {
            this.handleSubmit(event)
          }}
          className={formValidate === true ? 'form-validate' : ''}
        >
          <div className="form-inner">
            <div>
              <span className="legend">*INDICATES REQUIRED FIELD</span>
            </div>
            {settings.map((field, index) => {
              const FormComponent = FormFields[formatComponentName(field.type)]
              //Add quote to lead
              if (field.type === 'quoteList') {
                this.state.lead['quote'] = ls.get('quote')
              }
              return (
                <FormComponent
                  key={index}
                  field={field}
                  filter={filter}
                  value={lead}
                  handleChange={this.handleChange}
                />
              )
            })}
            <div className="submit-wrapper">
              <button
                aria-label="Submit"
                onMouseOver={e => this.checkCaptcha()}
                onClick={e => this.handleValidate(e)}
                className={`submit${
                  this.state.ready ? ' ready' : ' not-ready'
                }`}
              >
                Submit
              </button>
              <ReCAPTCHA
                className="captcha"
                ref={recaptchaRef}
                sitekey={siteKey}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpSettings {
          title
          siteUrl
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            googleSiteKey
          }
        }
        allWordpressWpForm {
          nodes {
            wordpress_id
            formJson {
              recipientAddress
              formFields {
                type
                required
                placeholder
                options {
                  label
                  value
                  filter
                }
                name
                className
                accept
                isFilter
                filter
                isMulti
              }
            }
          }
        }
      }
    `}
    render={data => <Index data={data} {...props} />}
  />
)
