import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Radio = (props) => {
  const { field, filter, value, handleChange } = props
  const {type, name, placeholder, required, className, options, isFilter = false } = field
  return (
    <div className={`${className}${value[slugify(name)] ? ' active' : ''}${required ? ' required' : ''}`}>
      <label>{placeholder}</label>
      <div className="radio">
        {options && options.map((option, index) => (
          <label htmlFor={`${slugify(name)}-${index}`} key={index}>
            {/* <input name={slugify(name)} id={`${slugify(name)}-${index}`} type="radio" checked={value[slugify(name)] === option.value || index === 0 && typeof(value[slugify(name)]) === 'undefined'} onChange={(e) => handleChange(e, isFilter)} required={required} value={option.value} /> */}
            <input name={slugify(name)} id={`${slugify(name)}-${index}`} type="radio" onChange={(e) => handleChange(e, isFilter)} required={required} value={option.value} />
            {option.value}
          </label>
        ))}
      </div>
    </div>
  )
}
