export const removeFromArray = ( arr, item ) => {
  for (var i = arr.length; i--;) {
    if (arr[i] === item) {
      arr.splice(i, 1);
    }
    //Check sub nav items
    if (arr[i] && arr[i].subnavigation) {
      for (var iv = arr[i].subnavigation.length; iv--;) {
        if (arr[i].subnavigation[iv] === item) {
          arr[i].subnavigation.splice(iv, 1);
        }
      }
    }
  }
  return arr;
}

var formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
});

export const numberToMoney = (number) => {
  return number ? formatter.format( parseInt(number) ) : 0
}
